<template>
  <div>
    <Hero />
    <HowItWorks />
    <Video />
    <Event />
    <CTA />
  </div>
</template>

<script>
import Hero from './components/hero'
import HowItWorks from './components/how-it-works'
import Video from './components/video'
import Event from './components/event'
import CTA from './components/cta'

export default {
  name: 'TechStartup',
  components: {
    Hero,
    HowItWorks,
    Video,
    Event,
    CTA
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: 'OpenAPIHub Suite for Tech startup | %s',
      link: [
        { rel: 'canonical', href: 'https://www.openapihub.com/tech-startup' }
      ],
      meta: [
        { name: 'description', content: 'Scale your startup with OpenAPIHub community.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'OpenAPIHub Suite for Tech Startup | OpenAPIHub' },
        { property: 'og:description', content: 'Scale your startup with OpenAPIHub community.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/openapihub-2.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/tech-startup' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
