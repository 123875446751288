<template>
  <div id="how-it-works" class="position-relative overflow-hidden">
    <div class="container space-2">
      <div class="w-md-85 w-lg-65 text-center mx-auto mb-7">
        <h2>{{ $t('TechStartupPage.FeatureSection.Title') }}</h2>
        <p>{{ $t('TechStartupPage.FeatureSection.Description') }}</p>
      </div>
      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <h3 class="mb-3">{{ $t('TechStartupPage.FeatureSection.Feature1Title') }}</h3>
          <p>{{ $t('TechStartupPage.FeatureSection.Feature1Description') }}</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/api-community.svg">
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0 order-md-2">
          <h3 class="mb-3">{{ $t('TechStartupPage.FeatureSection.Feature2Title') }}</h3>
          <p>{{ $t('TechStartupPage.FeatureSection.Feature2Description') }}</p>
        </div>
        <div class="col-12 col-md-6 order-md-1">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/construction.svg">
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <h3 class="mb-3">{{ $t('TechStartupPage.FeatureSection.Feature3Title') }}</h3>
          <p>{{ $t('TechStartupPage.FeatureSection.Feature3Description') }}</p>
          <!-- <a href="https://hub.openapihub.com/login" class="btn btn-primary" target="_blank">{{ $t('TechStartupPage.FeatureSection.Feature3Button') }}</a> -->
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/learnapi.svg">
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-12 col-md-6 mb-2 mb-md-0 order-md-2">
          <h3 class="mb-3">{{ $t('TechStartupPage.FeatureSection.Feature4Title') }}</h3>
          <p>{{ $t('TechStartupPage.FeatureSection.Feature4Description') }}</p>
          <!-- <a href="https://hub.openapihub.com/login" class="btn btn-primary" target="_blank">{{ $t('TechStartupPage.FeatureSection.Feature4Button') }}</a> -->
        </div>
        <div class="col-12 col-md-6 order-md-1">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/trust/trust-feature-1.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowItWorks'
}
</script>

<style lang="scss" scoped>
</style>
