<template>
  <div class="position-relative" style="background-color:#f7faff">
    <div class="container space-2">

      <div class="w-md-85 w-lg-65 text-center mx-auto mb-5">
        <h2>{{ $t('TechStartupPage.EventSection.Title') }}</h2>
        <p>{{ $t('TechStartupPage.EventSection.Description') }}</p>
      </div>

      <div class="row">
        <div v-for="(item, index) in EventList" :key="index" class="col-md-6 col-lg-4 mb-4">
          <div class="card h-100 cursor-pointer" @click="clickCard(item)">
            <div class="card-img-top position-relative">
              <img class="card-img-top" :src="require('@/assets/img/events/' + item.image_url)">
              <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                  <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                </svg>
              </figure>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <span class="d-block small font-weight-bold text-cap">{{ $t(item.category) }}</span>
              </div>
              <div class="mb-5">
                <h4>{{ item.title }}</h4>
                <p v-if="item.description" class="font-size-1">{{ item.description }}</p>
              </div>
              <div v-if="item.date" class="media text-body font-size-1 mb-2">
                <div class="min-w-3rem text-center mr-3">
                  <i class="far fa-calendar-alt" />
                </div>
                <div class="media-body">
                  {{ item.date }}
                </div>
              </div>
              <div v-if="item.time" class="media text-body font-size-1 mb-2">
                <div class="min-w-3rem text-center mr-3">
                  <i class="far fa-clock" />
                </div>
                <div class="media-body">
                  {{ item.time }}
                </div>
              </div>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">{{ $t('TechStartupPage.EventSection.LearnMore') }} <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Event',
  data() {
    return {
      EventList: [
        {
          router_link: '/community-updates/20220824-Apidays-Hong-Kong-2022',
          image_url: 'apidays-live-hongkong-2022.jpg',
          category: 'TechStartupPage.EventSection.Conference',
          title: 'Apidays Hong Kong 2022',
          description: 'API-First Digital Transformation & Platform Economy',
          date: 'Aug 24, 2021 (Wednesday) & Aug 25, 2021 (Thursday)',
          time: '9:00am - 6:00pm (HKT)',
          status: 'Event Ended'
        },
        {
          router_link: '/community-updates/20210329-Dive-Deep-on-Running-Modern-Application-with-AWS-Container-Services',
          image_url: '20210329-aws-techconnect-series.png',
          category: 'TechStartupPage.EventSection.Webinar',
          title: 'Dive Deep on Running Modern Application with AWS Container Services',
          description: 'In this webinar, we discuss best practices in containerization, and identify some common customer scenarios during the adoption of AWS container services.',
          date: 'Mar 29, 2021 (Monday)',
          time: '1:00pm - 2:15pm (HKT)',
          status: 'Event Ended'
        },
        {
          router_link: '/community-updates/20200814-API-EcoBooster',
          image_url: 'apiecobooster.jpg',
          category: 'TechStartupPage.EventSection.Challenge',
          title: 'API EcoBooster',
          description: 'Embrace New Technology with Open APIs in SMART BANKING',
          date: 'Aug, 2020 - Oct, 2020',
          status: 'Event Ended'
        }
      ]
    }
  },
  methods: {
    clickCard(card) {
      if (card.url) {
        window.open(card.url, '_blank')
      } else if (card.router_link) {
        this.$router.push({
          path: `/${this.$route.params.lang}${card.router_link}`
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
