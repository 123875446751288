<template>
  <div class="position-relative overflow-hidden">
    <div style="background-color:#dbecfb;">
      <div class="container space-top-2">
        <div class="w-md-85 w-lg-65 mx-auto text-center">
          <span class="d-block small font-weight-bold text-cap mb-2">{{ $t('TechStartupPage.HeroSection.Subtitle') }}</span>
          <h1 class="">{{ $t('TechStartupPage.HeroSection.Title') }}</h1>
          <p class="lead mb-4">{{ $t('TechStartupPage.HeroSection.Description') }}</p>
          <a id="" href="https://hub.openapihub.com/login" class="btn btn-primary mr-1 mr-sm-2 mb-4" target="_blank">{{ $t('TechStartupPage.HeroSection.Button') }}</a>
        </div>
      </div>
    </div>
    <img class="img-fluid" src="@/assets/svg/components/tech-startup-bg-light.svg">

    <div class="container">
      <!-- eslint-disable -->
        <div class="space-2">
          <div class="text-center mb-4">
            <h2 class="divider divider-xs divider-text">{{ $t('OW01HomePage.HeroSection.TrustedBy') }}</h2>
          </div>
          <Slick ref="CommunityProvidersCarousel" :options="slickOptions" class="align-items-center">
            <div class="js-slide">
              <img class="max-w-19rem mx-auto" src="@/assets/img/api-providers/fiberconnect-logo.svg">
            </div>
            <div class="js-slide">
              <img class="max-w-19rem mx-auto" src="@/assets/img/api-providers/taptab-logo.svg">
            </div>
            <div class="js-slide">
              <img class="max-w-19rem mx-auto" src="@/assets/img/api-providers/stemedia-logo.svg">
            </div>
            <div class="js-slide">
              <img class="max-w-19rem mx-auto" src="@/assets/img/api-providers/openimagelab-logo.svg">
            </div>
            <div class="js-slide">
              <img class="max-w-19rem mx-auto" src="@/assets/img/api-providers/atchative-logo.svg">
            </div>
            <div class="js-slide">
              <img class="max-w-19rem mx-auto" src="@/assets/img/api-providers/benovelty-logo.svg">
            </div>
          </Slick>
        </div>
			<!-- eslint-enable -->
    </div>

  </div>

</template>

<script>
import Slick from 'vue-slick'

export default {
  name: 'Hero',
  components: {
    Slick
  },
  data() {
    return {
      typedKeywords: [],
      slickOptions: {
        slidesToShow: 5,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        prevArrow: '<span class="fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-n3 d-none" />',
        nextArrow: '<span class="fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-n3 d-none" />',
        responsive: [{
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          }
        }, {
          breakpoint: 992,
          settings: {
            slidesToShow: 4
          }
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        }, {
          breakpoint: 576,
          settings: {
            slidesToShow: 2
          }
        }]
      }
    }
  },
  beforeUpdate() {
    if (this.$refs.CommunityProvidersCarousel) {
      this.$refs.CommunityProvidersCarousel.destroy()
    }
  },
  updated() {
    this.$nextTick(function() {
      if (this.$refs.CommunityProvidersCarousel) {
        this.$refs.CommunityProvidersCarousel.create(this.slickOptions)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~slick-carousel/slick/slick.css';

.modal-header {
  border-bottom: 0;
}
</style>
